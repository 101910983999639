const { Container } = require("postcss");

/*
 * Site Navigation
 */
(function($) {

    //Search Button
    $('.navbar-iconset--subset.search').on('click', function(event) {
        event.stopPropagation();
        $(this).toggleClass('open');
        $(this).closest('.navbar-iconset').find('.search-dropdown').toggleClass('open');
        $(this).closest('.navbar-iconset').find('.open .search-dropdown--input').focus();

    });

    //Toggle Click Outside of searchbox area
    $(document).on('click', function (e) {
        const search = $('.search-dropdown');
        const icon   = $('.navbar-iconset--subset')
        if(!search.is(e.target) && search.has(e.target).length == 0) {
            icon.removeClass('open');
            search.removeClass('open');
        }
    })
   
    //Mobile Hamburger Button
    $('.header-container .navbar-toggler').on('click', function() {
        $(this).toggleClass('open');
        $(this).closest('.site-header').find('.drop-menu').toggleClass('open');
        $(this).closest('.site-header').find('.wf-header-main').toggleClass('open')
    });
    
    //In This Section Button
    $('#InThisSectionMenuButton .hamburger').on('click', function() {
        $('.in-this-section--menu').toggleClass('open');
    })

})(jQuery);
