/**
 * Filter Programing List
 */
(function($) {
    // $('#program-filter-btn').on('click', function () {
    //     $('.filter-controls-filter_area').toggle();
    // })

    $('.dropdown').on('click', function() {
        $(this).toggleClass('open')
    })

    $('#clear-programs').on('click', function () {
        
        let checkboxes = $('input[type="checkbox"]:checked');
        
        //do nothing
        if( checkboxes.length === 0 ) {
            return;
        }

        for ( var i = 0; i < checkboxes.length; i++ ) {
            checkboxes[i].checked = false;
        }

        location.reload()

    })

    $('#program-listing-search-input').keyup( function() {
        $.ajax({
            url: '/wp-admin/admin-ajax.php',
            type: 'POST',
            data: {
                action: 'program_listing_search',
                keyword: $('#program-listing-search-input').val()
            },
            success: function ( data ) {
                $('.program-lists').html( data );               
            }
        })
    })
 
 
     
    $(".filter-controls--dropdown-section").find('input:checkbox').on('click', function() {
        var programs = {};
        

        $('.program-lists').empty();

        $('input[type=checkbox]:checked').each(function() {

            if ( !programs.hasOwnProperty(this.name) ) 
                programs[this.name] = [this.value];
            else 
                programs[this.name].push(this.value);

            
        });
 
        $.ajax({
            url: '/wp-admin/admin-ajax.php',
            type: 'POST',
            data : {
                'action' : 'program_listing',
                'programs' : programs,      
                
                
            },
            success: function ( response ) {

                // let data = JSON.parse( response ) 
                  
                $('.program-lists').html( response );

                  //TOggle when ajax
                $('.toggle-show-more-description').on('click', function () {
                    let content = $(this).parent().find('.program-detail-body')
                    let linkText = $(this).text().toUpperCase();
                    if( linkText === "MORE" ) {
                        linkText = "less";
                        content.removeClass('hide-content')
                        content.addClass('show-content')
                        $(this).addClass('showing')
                        $(this).removeClass('hiding')
                    }else{
                        linkText = "more";
                        content.removeClass("show-content")
                        content.addClass("hide-content")
                        $(this).addClass('hiding')
                        $(this).removeClass('showing')
                    }
            
                    $(this).text(linkText)  
                    
                })
              
            },
            error: function(err){
                 console.log(err);
             }
        })        
    })

  
    

    //More
    $('.toggle-show-more-description').on('click', function () {
        let content = $(this).parent().find('.program-detail-body')
        let linkText = $(this).text().toUpperCase();
        if( linkText === "MORE" ) {
            linkText = "less";
            content.removeClass('hide-content')
            content.addClass('show-content')
            $(this).addClass('showing')
            $(this).removeClass('hiding')
        }else{
            linkText = "more";
            content.removeClass("show-content")
            content.addClass("hide-content")
            $(this).addClass('hiding')
            $(this).removeClass('showing')
        }

        $(this).text(linkText)



       
    })

    $('#program-filter-btn').on('click', function () {
        $('#program-search-btn').removeClass('filter-active')
        $('#search-content').slideUp("fast");
        $('#filters-content').toggle("fast")
        // $(this).toggleClass('filter-active')

        return false
    })
    $('#program-search-btn').on('click', function () {
        $('#program-filter-btn').removeClass('filter-active')
        $('#filters-content').slideUp("fast");
        $('#search-content').toggle("fast")
        $(this).toggleClass('filter-active')
        return false
    })


})(jQuery);