jQuery(function() { 
  
    socialToggle()

});

function loadFacebookShare( url, redirect, title ) {
    var strWindowFeatures = "location=yes,height=400,width=685,scrollbars=yes,status=yes";
	var URL = "https://www.facebook.com/dialog/share?" +
	"app_id=" +
	"&display=popup" +
	"&href=" + url +
	"&redirect_uri=" + redirect;
	return window.open(URL, "_blank", strWindowFeatures);
}

function loadTwitterShare( url, text, title) {
    var strWindowFeatures = "location=yes,height=400,width=685,scrollbars=yes,status=yes";
    var URL = "https://twitter.com/intent/tweet?" +
		"&text=" + text +
		"&url=" + url;
	return window.open(URL, "_blank", strWindowFeatures);
}

function loadPinterestShare( url, text, media, title) {
	var strWindowFeatures = "location=yes,height=400,width=685,scrollbars=yes,status=yes";
    var URL = "http://pinterest.com/pin/create/button/?" +
		"&description=" + text +
		"&media=" + media +
		"&url=" + url;
	return window.open(URL, "_blank", strWindowFeatures);
}

function socialToggle() {
    $('.social-icons--link').on('click', function (e) {
         var share = $(this).attr('title');
        if( share == 'Twitter') {
            loadTwitterShare( jQuery( this ).data( 'url' ), jQuery( this ).data( 'text' ), jQuery( this ).data( 'title' ) );
        }else if ( share == 'Pinterest') {
            loadPinterestShare( jQuery( this ).data( 'url' ), jQuery( this ).data( 'text' ), jQuery( this ).data( 'media' ), jQuery( this ).data( 'title' ) );
        } 
    })
}