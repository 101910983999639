/*
 * Google Translate
 */
(function($) {

    // function googleTranslateElementInit() {
    //     $('.google_translate').each(function() {
    //         new google.translate.TranslateElement({pageLanguage: 'en'}, $(this).attr('id'));
    //     });
    // }

})(jQuery);
