(function($) {

    $('.in-this-section').clone(true).addClass('d-block d-lg-none').insertAfter('.wp-block-separator:first')

 
    if ($(window).width() < 960) {
        $('.in-this-section:eq(0)').remove()
     }
    

})(jQuery);
