$( document ).ready(function() {

    // When you click on the +/- button it expands
    $('.btn-expander').on('click keypress', function() {
        if($(this).hasClass('collapsed')) {
            $(this).removeClass('collapsed');
            $($(this).attr('data-target')).slideDown('slow', function(){
                $($(this).attr('data-target')).removeClass('collapse');
            });
        } else {
            $(this).addClass('collapsed');
            $($(this).attr('data-target')).slideUp('slow', function(){
                $($(this).attr('data-target')).addClass('collapse');
            });
        }
    });

    //Table
    if( $(window).width() < 600 ) {
      
        const table = $('.table');
        const th    = $('thead th');
        //const td    = $.makeArray(th).map(el => el.innerText);
        const tdLabels = $(th).toArray().map(el => el.innerText)
       
      $('table th').each(function(i, elem) {
        var num = i + 1;
        $('table td:nth-child(' + num + ')').attr('data-label', $(elem).text())
      })
    
    }

    //Step progress back buttons
	$('.prev-step').on('click', function() {
        $(this).closest('.resp-tabs-list').prev('li').trigger('click');
    });

    //Step progress forward buttons
	$('.next-step').on('click', function(){
        $(this).closest('.resp-tabs-list').next('li').trigger('click');
    });

});